import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Youtube = makeShortcode("Youtube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Extracting data from The Grid`}</h1>
    <p>{`To extract data from the grid, channel it into a modulator out device inside of the grid.
Now just map it to whatever you want outside of the grid.`}</p>
    <h2>{`Note Out Preset`}</h2>
    <p>{`You can also use Polaritys preset called "`}<a parentName="p" {...{
        "href": "https://github.com/polarity/polarity-music-tools/raw/master/Bitwig-3.1/Grid%20Note%20Out.bwpreset"
      }}>{`Note Out`}</a>{`"`}</p>
    <Youtube link="https://www.youtube.com/watch?v=0G1i8I_1Iz4" title="Grid Note Out" mdxType="Youtube">
    </Youtube>
    <h2>{`Need more?`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/tips-tricks"
        }}>{`more Bitwig Tips & Tricks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/"
        }}>{`BitWiki Overview`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      